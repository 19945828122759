//BRAND
export const name = 'AiCourse.Bot';
export const company = 'AiCourse.Bot';
export const websiteURL = 'https://aicourse.bot';
export const serverURL = 'https://ai-course-generator-bot.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/aicourse-bot-generator.appspot.com/o/Ai.svg?alt=media&token=08e9f74f-98ce-4cbd-8929-2b1d909250cc';
export const razorpayEnabled = false;
export const paypalEnabled = true;

//VALUES    
export const initialFormValues = [{ sub: "" }]; //Initial form values

//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 9;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 99;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "The AI Course Generator has completely transformed how I create course content. With its precision in generating relevant topics, I've been able to save time while significantly enhancing the quality and pertinence of my courses. This tool is indispensable for any educator aiming to boost efficiency and deliver powerful online learning experiences.";
export const from = "Daniel Schroller";
export const profession = 'CFO at Spacester';
export const photoURL = 'https://play-lh.googleusercontent.com/sV_ffBmBJt_je4RZHnfaCfcnL-Hy6C14Iol7H5EMj9fzI2GDOonuojdn5t9p6n9IAX8j';

//PAYPAL
export const paypalPlanIdOne = "P-54A305892Y1894701MYM4B6A";
export const paypalPlanIdTwo = "P-44U37476VR014141AMYM4CSY";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";
